const mockOrFetch = ({ apiUrl, fetchOpts = {}, mockData }) =>
	new Promise((resolve, reject) => {
		if (process.env.MOCK_API) {
			return resolve(mockData);
		}

		return fetch(apiUrl, fetchOpts)
			.then(response => response)
			.then(data => resolve(data))
			.catch(err => reject(err));
	});

export default mockOrFetch;
