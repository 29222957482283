/**
 * Class representing the log queue
 * @category Logging
 */
class LogQueue {
	constructor(queue = []) {
		this.queue = [];

		// Parse Queue if a string is passed in
		try {
			let parsedQueue = queue;

			if (typeof queue === "string") {
				parsedQueue = JSON.parse(queue);
			}

			Object.assign(this, parsedQueue);
		} catch (e) {
			console.error(`Error creating LogQueue with ${queue}`);
		}
	}

	/**
	 * Add to log Queue
	 * @method
	 * @param logMessage {Object}
	 * @returns {Array}
	 */
	add(logMessage) {
		if (logMessage) {
			this.queue.push(logMessage);
		}

		return this.queue;
	}

	/**
	 * Shows first item in log queue
	 * @method
	 * @returns {*}
	 */
	peek() {
		return this.queue[0];
	}

	/**
	 * Removes first item from queue
	 * @method
	 * @returns {T}
	 */
	dequeue() {
		return this.queue.shift();
	}

	/**
	 * Removes items from queue
	 * @method
	 * @returns {Array}
	 */
	clear() {
		this.queue = [];
		return this.queue;
	}

	/**
	 * Checks length of queue
	 * @method
	 * @returns {number}
	 */
	size() {
		return this.queue.length;
	}
}

export default LogQueue;
