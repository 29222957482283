/**
 * Class representing the core log message sent to the logging API
 * @category Logging
 */
class LogMessage {
	/**
	 * Builds out the Log Message to add the Tracking ID and Additional Arguments to the message
	 * @param {string} level Log Level ()
	 * @param {UUID} trackingId UUID generated for the session by the application
	 * @param {string} logInstance
	 * @param {string} message
	 * @param {Array} args n Number of additional arguments that will be serialized
	 */
	constructor(
		applicationName,
		applicationCode,
		level,
		logInstance,
		trackingId,
		message,
		args
	) {
		this.applicationName = applicationName;
		this.applicationCode = applicationCode;
		this.level = level;
		this.logInstance = logInstance;
		this.message = message;
		this.timestamp = new Date();
		this.transactionId = trackingId;

		if (args && args.length > 0) {
			this.message += `|Additional Arguments: ${JSON.stringify(args)}`;
		}

		return this;
	}
}

export default LogMessage;
