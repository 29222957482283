/* spell-checker: disable */

const DEFAULTS = {
	CNT_APIM_RCL_SWG:
		"https://apiuat.sunwingapi.com/content/opn/rcl?content_type=applicationResources&environment=master&fields.application.sys.id[match]=5mOB6W4JnVYyMUij7YSzWB&limit=1000",
	CNT_APIM_RCL_VWQ:
		"https://apiuat.sunwingapi.com/content/opn/rcl?content_type=applicationResources&environment=VWQ-dev1&fields.application.sys.id[match]=5mOB6W4JnVYyMUij7YSzWB&limit=1000",
	LOGGING_API: "https://weblogging.sunwingtravelgroup.com/api/v1",
	SUNWING_API: "http://sunwingcaresapiuat2.sunwingtravelgroup.com/api/v1",
	FORMASSEMBLY_PROCESSOR_API: "/processorapi",
	FORMASSEMBLY_Form_ID: "",
	FORMASSEMBLY_DB_CONTROL: "",
	ENABLE_LOGGING: true,
	CONTENTFUL_SPACE_RCL: "bjgonf6fglpm",
	RCL_APP_CODE: "SWC",
	LANGUAGE: "en",
	BRAND: "swg"
};

// Configs / API / Endpoints
const CONFIGS = {
	_cntSwgApi: "",
	_cntVwqApi: "",
	_loggingApi: "",
	_sunwingApi: "",
	_formAssemblyProcessorApi: "",
	_formAssemblyFormId: "",
	_formAssemblyDbControl: "",

	initialize({
		cntSwgApi,
		cntVwqApi,
		loggingApi,
		enableLogging,
		sunwingApi,
		formAssemblyProcessorApi,
		formAssemblyFormId,
		formAssemblyDbControl
	}) {
		this._cntSwgApi = cntSwgApi;
		this._cntVwqApi = cntVwqApi;
		this._loggingApi = loggingApi;
		this._enableLogging = enableLogging;
		this._sunwingApi = sunwingApi;
		this._formAssemblyProcessorApi = formAssemblyProcessorApi;
		this._formAssemblyFormId = formAssemblyFormId;
		this._formAssemblyDbControl = formAssemblyDbControl;
		return new Promise(resolve => resolve(this));
	},

	get LOGGER() {
		return {
			API: this._loggingApi,
			ENABLE: this._enableLogging
		};
	},

	get DCISSWG() {
		return this._cntSwgApi;
	},

	get DCISVWQ() {
		return this._cntVwqApi;
	},

	get BOOKING() {
		return {
			SEARCH: (lang, bookingId, lastName, brand) =>
				`${this._sunwingApi}/Booking?bookingId=${bookingId}&LastName=${lastName}&language=${lang}&brand=${brand}`
		};
	},
	get QUESTION() {
		return {
			CREATE: () => `${this._formAssemblyProcessorApi}`
		};
	},

	get FORMASSEMBLYFOORMID() {
		return this._formAssemblyFormId;
	},

	get FORMASSEMBLYDBCONTROL() {
		return this._formAssemblyDbControl;
	}
};

module.exports = {
	DEFAULTS,
	CONFIGS
};
