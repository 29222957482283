/**
 * Fetch API Class that returns API return response for GC API
 * @category API
 */
class UCMFetch {
	/**
	 * Initialize with API Url
	 * @param apiUrl
	 * @property {string} ._apiUrl
	 * @property {boolean} ._retry
	 * @property {boolean} ._forceGUID
	 * @property {boolean} ._cancelled
	 * @example
	 * let getFormFetch = new UCMFetch(`api.url`)
	 */
	constructor(apiUrl) {
		this._apiUrl = apiUrl;
		this._retry = false;
		this._forceGUID = false;
		this._cancelled = false;
	}

	/**
	 * GET HTTP Request Method
	 * @method
	 * @param endpoint {string}
	 * @param payload {Object}
	 * @returns {Promise<{body, ok, status}|{ok: boolean, body: string, status: number}>}
	 * @example
	 * let getFormFetch = new UCMFetch(`api.url`)
	 * const response = await getFormFetch.get({
	 *   endpoint: "api/endpoint",
	 *   payload: {Payload}
	 * })
	 */
	async get({ endpoint = "", payload }) {
		this._cancelled = false;

		let response = await this._fetch("GET", endpoint, payload);
		if (this._retry) {
			response = await this._fetch("GET", endpoint, payload);
		}

		if (this._cancelled) {
			return {
				ok: false,
				status: 499,
				body: "Cancelled Request"
			};
		}
		return response;
	}

	/**
	 * POST HTTP Request Method
	 * @method
	 * @param endpoint {string}
	 * @param payload {Object}
	 * @returns {Promise<{body, ok, status}|{ok: boolean, body: string, status: number}>}
	 * @example
	 * let getFormFetch = new UCMFetch(`api.url`)
	 * const response = await getFormFetch.post({
	 *   endpoint: "api/endpoint",
	 *   payload: {Payload}
	 * })
	 */
	async post({ endpoint = "", payload }) {
		this._cancelled = false;

		let response = await this._fetch("POST", endpoint, payload);
		if (this._retry) {
			response = await this._fetch("POST", endpoint, payload);
		}

		if (this._cancelled) {
			return {
				ok: false,
				status: 499,
				body: "Cancelled Request"
			};
		}
		return response;
	}

	/**
	 * PUT HTTP Request Method
	 * @method
	 * @param endpoint
	 * @param payload
	 * @returns {Promise<{body, ok, status}|{ok: boolean, body: string, status: number}>}
	 * @example
	 * let getFormFetch = new UCMFetch(`api.url`)
	 * const response = await getFormFetch.put({
	 *   endpoint: "api/endpoint",
	 *   payload: {Payload}
	 * })
	 */
	async put({ endpoint = "", payload }) {
		this._cancelled = false;

		let response = await this._fetch("PUT", endpoint, payload);
		if (this._retry) {
			response = await this._fetch("PUT", endpoint, payload);
		}

		if (this._cancelled) {
			return {
				ok: false,
				status: 499,
				body: "Cancelled Request"
			};
		}
		return response;
	}

	/**
	 * DELETE HTTP Request Method
	 * @method
	 * @param endpoint
	 * @param payload
	 * @returns {Promise<{body, ok, status}|{ok: boolean, body: string, status: number}>}
	 * @example
	 * let getFormFetch = new UCMFetch(`api.url`)
	 * const response = await getFormFetch.delete({
	 *   endpoint: "api/endpoint",
	 *   payload: {Payload}
	 * })
	 */
	async delete({ endpoint = "", payload }) {
		this._cancelled = false;

		let response = await this._fetch("DELETE", endpoint, payload);
		if (this._retry) {
			response = await this._fetch("DELETE", endpoint, payload);
		}

		if (this._cancelled) {
			return {
				ok: false,
				status: 499,
				body: "Cancelled Request"
			};
		}
		return response;
	}

	/**
	 * Cancel the request
	 * @method
	 * @example
	 * let getFormFetch = new UCMFetch(`api.url`)
	 * getFormFetch.cancel()
	 */
	cancel() {
		this._cancelled = true;
	}

	/**
	 * Fetch Method that handles fetch inside other methods in this class
	 * @see Used in {@link UCMFetch#get} {@link UCMFetch#post} {@link UCMFetch#put} and {@link UCMFetch#delete}
	 * @method
	 * @param type {string} HTTP Method
	 * @param endpoint {string}
	 * @param payload {Object}
	 * @returns {Promise<{body: *, ok: boolean, status: boolean}|{body, ok, status}>}
	 * @example
	 * class UCMFetch {
	 *   get = async ({endpoint="", payload}) => {
	 *   let response = await this._fetch("POST", endpoint, payload)
	 *   return response
	 * }
	 */
	async _fetch(type, endpoint, payload) {
		this._retry = false;
		try {
			let _apiEndpointUrl = `${this._apiUrl}${endpoint}`;

			if (type === "GET" && payload) {
				const query = Object.keys(payload)
					.map(
						key =>
							`${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`
					)
					.join("&");

				_apiEndpointUrl += `?${query}`;
			}

			const response = await fetch(_apiEndpointUrl, {
				method: type,
				headers: {
					"Content-Type": "application/json",
					Authorization: this._getAuthHeader()
				},
				body: type !== "GET" ? JSON.stringify(payload) : null
			});
			return await this._handleResponse(response);
		} catch (error) {
			return {
				body: error.message,
				ok: false,
				status: false
			};
		}
	}

	/**
	 * Returns header for HTTP Requests
	 * @method
	 * @returns {string}
	 * @example
	 * class UCMFetch {
	 *   _fetch = async (type, endpoint, payload) => {
	 *   const response = await fetch("some.endpoint", {
	 *     method: type,
	 *     headers: {
	 *       "Content-Type": "application/json",
	 *       Authorization: this._getAuthHeader()
	 *     },
	 *     body: JSON.stringify(payload)
	 *   });
	 * }
	 */
	_getAuthHeader() {
		const jwt = sessionStorage.getItem("access_token");
		const guid = localStorage.getItem("guid");

		if (this._forceGUID && guid) {
			this._forceGUID = false;
			return `GUID ${guid}`;
		}

		if (jwt) {
			return `Bearer ${jwt}`;
		}
		if (guid) {
			return `GUID ${guid}`;
		}

		return null;
	}

	/**
	 * Handles response of request
	 * @see Used inside {@link UCMFetch#_fetch} Object
	 * @method
	 * @param response {Response}
	 * @returns {Promise<{body: *, ok: *, status: *}>}
	 * @example
	 * class UCMFetch {
	 *    _fetch = async (type, endpoint, payload) => {
	 *   const response = await fetch("some.endpoint", {optionsObj});
	 *   return await this._handleResponse(response)
	 * }
	 */
	async _handleResponse(response) {
		// Sets the access token into session storage if one was provided in the response
		const accessToken = response.headers.get("AccessToken");
		if (accessToken) {
			sessionStorage.setItem("access_token", accessToken);
		}

		const body = await response.text();

		try {
			const parsedBody = JSON.parse(body);
			if (response.ok) {
				return {
					body: parsedBody,
					ok: response.ok,
					status: response.status
				};
			}
			if (response.status === 500 && parsedBody.errors) {
				if (parsedBody.errors[0] && parsedBody.errors[0].code) {
					const errorCode = parsedBody.errors[0].code;
					// Access Token Expired
					if (errorCode === 100) {
						// Retry using GUID
						this._forceGUID = true;
						this._retry = true;
						return {
							body: parsedBody.errors,
							ok: response.ok,
							status: response.status
						};
					}
				}
			}
			return {
				body: parsedBody,
				ok: response.ok,
				status: response.status
			};
		} catch (error) {
			// Error comes from response not being JSON Formatted
			return {
				body: body || response.statusText,
				ok: response.ok,
				status: response.status
			};
		}
	}
}

export default UCMFetch;
