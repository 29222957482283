import isUUID from "validator/lib/isUUID";
import isEmpty from "validator/lib/isEmpty";
import isURL from "validator/lib/isURL";
import LogQueue from "./LogQueue";
import LogMessage from "./LogMessage";
import UCMFetch from "../ucm-fetch";

/**
 * UUID / GUID
 * @typedef {(string)} UUID
 */

/**
 * Available Log Levels
 * @readonly
 * @enum {string}
 * @category Logging
 * @see {@link LoggerInstance}
 */
const LOG_LEVELS = {
	TRACE: "Trace",
	DEBUG: "Debug",
	INFO: "Information",
	WARN: "Warning",
	ERROR: "Error",
	FATAL: "Fatal"
};
Object.freeze(LOG_LEVELS);

/**
 * Log Manager Class
 * @category Logging
 */
class LogManager {
	constructor({
		apiUrl,
		apiKey,
		applicationName,
		applicationCode,
		trackingId,
		storageKeyPrefix = "stg-web-log-cache",
		batchLogs = false,
		batchLogsMaxSize = 25,
		sendLogsPeriod = 10000
	}) {
		// Ensure logger is initialized only once
		// eslint-disable-next-line no-prototype-builtins
		if (this.constructor.hasOwnProperty("instance")) {
			return this.constructor.instance;
		}

		this._apiUrl = apiUrl;
		this._apiKey = apiKey;
		this._ucmFetch = new UCMFetch(`${this._apiUrl}?apiKey=${this._apiKey}`);
		this._applicationName = applicationName;
		this._applicationCode = applicationCode;
		this._trackingId = trackingId;

		this._storageKeyPrefix = storageKeyPrefix;
		this._batchLogs = batchLogs;
		this._batchLogsMaxSize = batchLogsMaxSize;

		this._sendLogsPeriod = sendLogsPeriod;
		this._validSetup = false;

		this._queue = null;
		this._oldestQueue = new LogQueue();
		this._storageKey = [];

		this._timerRef = null;
		this._isSending = false;
		this._isResettingRetries = false;
		this._retries = 0;
		this._maxRetries = 3;
		this._retryTimeout = 5000;

		this._paused = false;

		this._initializedUnmount = false;

		// Validate constructor and initialize Singleton
		try {
			this._validSetup = this._validateConstructor();
			this._setStorageKeyAndQueue();
			this.constructor.instance = this;

			// Set up loop to process any outstanding logs that were not sent to the server
			// in the previous instance and new log messages
			this._timerRef = setInterval(() => {
				if (this._oldestQueue.size() > 0) {
					this._send();
				} else if (this._queue.size() > 0) {
					this._send(true);
				} else if (this._initializedUnmount) {
					clearInterval(this._timerRef);
				}
			}, this._sendLogsPeriod || 500);

			return this;
		} catch (err) {
			const errors = err.reduce(
				(prev, curr, index) => {
					// eslint-disable-next-line no-param-reassign
					prev.param += `${index !== 0 ? "," : ""} ${curr.param}`;
					prev.error.push(curr.error);

					return prev;
				},
				{
					param: "The following parameter(s) were invalid:",
					error: []
				}
			);

			console.error(errors.param, errors.error);

			return false;
		}
	}

	/**
	 * Validates all constructor values.
	 * @method
	 */
	_validateConstructor() {
		this._validSetup = false;
		const errors = [];

		// Validate apiUrl to be a valid URL
		try {
			if (!isURL(this._apiUrl, { require_tld: false })) {
				errors.push({
					param: "apiUrl",
					error: "apiUrl provided was not a valid URL"
				});
			}

			// Validate if url is https
			if (!isURL(this._apiUrl, { require_tld: false, protocols: ["https"] })) {
				errors.push({
					param: "apiUrl",
					error: "apiUrl provided did not have a 'https' protocol"
				});
			}
		} catch (e) {
			errors.push({
				param: "apiUrl",
				error: "apiUrl was not provided"
			});
		}

		// Validate apiKey to be an UUID
		try {
			if (!isUUID(this._apiKey)) {
				errors.push({
					param: "apiKey",
					error: "apiKey provided was not a valid UUID"
				});
			}
		} catch (e) {
			errors.push({
				param: "apiKey",
				error: "apiKey was not provided"
			});
		}

		// Validate applicationName to be defined
		try {
			if (isEmpty(this._applicationName, { ignore_whitespace: true })) {
				errors.push({
					param: "applicationName",
					error: "applicationName provided was empty"
				});
			}
		} catch (e) {
			errors.push({
				param: "applicationName",
				error: "applicationName was not provided"
			});
		}

		// Validate applicationName to be defined
		try {
			if (isEmpty(this._applicationCode, { ignore_whitespace: true })) {
				errors.push({
					param: "applicationCode",
					error: "applicationCode provided was empty"
				});
			}
		} catch (e) {
			errors.push({
				param: "applicationCode",
				error: "applicationCode was not provided"
			});
		}

		// Validate trackingId to be an UUID
		try {
			if (!isUUID(this._trackingId)) {
				errors.push({
					param: "trackingId",
					error: "trackingId provided was not a valid UUID"
				});
			}
		} catch (e) {
			errors.push({
				param: "trackingId",
				error: "trackingId was not provided"
			});
		}

		if (errors.length > 0) {
			throw errors;
		}

		return true;
	}

	/**
	 * Updates tracking id after validating it - if invalid, it will revert back to the initial tracking id.
	 * @method
	 * @param {UUID} trackingId
	 */
	updateTrackingId(trackingId) {
		const oldTrackingId = this._trackingId;

		this._trackingId = trackingId;

		try {
			this._validSetup = this._validateConstructor();
		} catch (e) {
			this._trackingId = oldTrackingId;
			console.error(
				`Tracking ID provided was not a valid UUID. Reverting back to original trackingId = ${this._trackingId}`
			);
		}
	}

	/**
	 * Creates the log object required by Web Logging API
	 * @method
	 * @param {string} level
	 * @param {string} logInstance
	 * @param {string} message
	 * @param {any[]} args
	 * @returns {LogMessage}
	 */
	_createLogMessage(level, logInstance, message, args) {
		return new LogMessage(
			this._applicationName,
			this._applicationCode,
			level,
			logInstance,
			this._trackingId,
			message,
			args
		);
	}

	/**
	 * Searches through localStorage to see if there is any existing logs setup based on the storage key prefix provided.
	 * If using batchLogs, it will check to see if there is an existing log and the size of it based on batchLogsMaxSize.
	 * @method
	 * @returns {LogQueue} the current queue
	 */
	_setStorageKeyAndQueue(logMessage) {
		const newStorageKey = `${this._storageKeyPrefix}-${new Date().getTime()}`;

		if (logMessage) {
			// Check if batchLogs is used and if max batch size has been reached
			if (this._batchLogs) {
				if (this._queue.size() >= this._batchLogsMaxSize) {
					// Reference new storage key
					this._storageKey.push(newStorageKey);
					// Create new queue
					this._queue = new LogQueue();
					this._queue.add(logMessage);
					return newStorageKey;
				}

				this._queue.add(logMessage);
				return this._storageKey.slice(-1)[0];
			}
			this._queue.add(logMessage);
			return this._storageKey.slice(-1)[0];
		}

		// Ran at initialization and after logs are sent to gather new list of storage keys
		this._storageKey = [];

		// Get all localStorage keys use for cache - separated so that we know the total number of keys still in localStorage
		for (let i = 0; i < localStorage.length; i += 1) {
			const _storageKey = localStorage.key(i);
			if (_storageKey.indexOf(this._storageKeyPrefix) === 0) {
				// Clean up old empty queues
				const queue = new LogQueue(localStorage.getItem(_storageKey));

				if (queue.size() === 0) {
					localStorage.removeItem(_storageKey);
				} else {
					this._storageKey.push(_storageKey);
				}
			}
		}

		// Sort from newest keys to oldest since it is prefix + timestamp
		this._storageKey.sort();

		// If no keys are set in cache - create new one
		if (this._storageKey.length === 0) {
			// Reference new storage key
			this._storageKey.push(newStorageKey);
			// Create new queue
			this._queue = new LogQueue();
		} else {
			// Loop through list of storageKeys in order to only add onto the last key
			const currentNumberOfKeys = this._storageKey.length;
			for (let i = 0; i < currentNumberOfKeys; i += 1) {
				const _storageKey = this._storageKey[i];
				const queue = new LogQueue(localStorage.getItem(_storageKey));

				// Check if max batch size is used
				if (this._batchLogs) {
					// If last key - create new key is needed
					if (i === currentNumberOfKeys - 1) {
						if (queue.size() >= this._batchLogsMaxSize) {
							// Reference new storage key
							this._storageKey.push(newStorageKey);
							// Create new queue
							this._queue = new LogQueue();
						} else {
							// Reference existing key
							this._queue = queue;
						}
					}
				} else {
					// If not using batch logs, use last key for current queue
					this._queue = queue;
				}
			}

			if (this._storageKey.length > 1) {
				this._oldestQueue = new LogQueue(
					localStorage.getItem(this._storageKey[0])
				);
			}
		}

		return this._storageKey.slice(-1)[0];
	}

	/**
	 * Used to create a persistent way of keeping log messages queued but not sent
	 * @method
	 * @param logMessage
	 * @private
	 */
	_setLocalStorage(logMessage) {
		let _storageKey = this._storageKey.slice(-1)[0];
		if (logMessage) {
			_storageKey = this._setStorageKeyAndQueue(logMessage);
			localStorage.setItem(_storageKey, JSON.stringify(this._queue));
		} else {
			// Resetting oldest queue
			const _oldestStorageKey = this._storageKey[0];
			if (this._batchLogs) {
				localStorage.removeItem(_oldestStorageKey);
			} else if (this._storageKey.length > 1) {
				if (this._oldestQueue.size() === 0) {
					localStorage.removeItem(_oldestStorageKey);
				} else {
					localStorage.setItem(
						_oldestStorageKey,
						JSON.stringify(this._oldestQueue)
					);
				}
			} else {
				localStorage.setItem(_storageKey, JSON.stringify(this._queue));
			}

			this._setStorageKeyAndQueue();
		}
	}

	/**
	 * Add to queue of log messages
	 * @method
	 * @param logMessage
	 * @private
	 */
	_addToQueue(logMessage) {
		if (this._paused) return;

		this._setLocalStorage(logMessage);

		if (this._sendLogsPeriod === 0) {
			if (this._oldestQueue.size() > 0) {
				this._send();
			} else if (this._queue.size() > 0) {
				this._send(true);
			}
		}
	}

	/**
	 * Function used by the log queue to send to the server
	 * @method
	 */
	async _send(sendNewQueue = false) {
		const errors = [];

		if (this._paused) return;

		// Handle Max Retries + Resetting
		if (this._retries === this._maxRetries && !this._isResettingRetries) {
			this._isResettingRetries = true;
			setTimeout(() => {
				this._retries = 0;
				this._isResettingRetries = false;
			}, this._retryTimeout);
		}

		if (!this._isSending && this._retries < this._maxRetries) {
			this._isSending = true;

			// Send oldest queue first
			let logBody = this._queue.peek();

			if (this._batchLogs) {
				if (sendNewQueue) {
					logBody = this._queue.queue;
				} else {
					logBody = this._oldestQueue.queue;
				}
			} else if (sendNewQueue) {
				logBody = this._queue.peek();
			} else {
				logBody = this._oldestQueue.peek();
			}

			const response = await this._ucmFetch.post({ payload: logBody });

			if (response.ok) {
				if (this._batchLogs) {
					if (sendNewQueue) {
						logBody = this._queue.clear();
					} else {
						logBody = this._oldestQueue.clear();
					}
				} else if (sendNewQueue) {
					logBody = this._queue.dequeue();
				} else {
					logBody = this._oldestQueue.dequeue();
				}

				this._setLocalStorage();
			} else if (
				response.status &&
				response.status !== 401 &&
				response.status !== 404
			) {
				// If response.status is true, it is an API issue (could be malformed log body)
				// Remove logs sent and push to errors to be made into new log message
				// Ignore UCM Unauthorized Errors and 404 Errors
				if (this._batchLogs) {
					if (sendNewQueue) {
						logBody = this._queue.clear();
					} else {
						logBody = this._oldestQueue.clear();
					}
				} else if (sendNewQueue) {
					logBody = this._queue.dequeue();
				} else {
					logBody = this._oldestQueue.dequeue();
				}

				errors.push({
					error: response.body,
					logBody: JSON.stringify(logBody)
				});
			} else {
				// Retry again before sending other queued messages
				this._retries += 1;
			}

			this._isSending = false;
			if (errors.length > 0) {
				this.error(
					"Previous attempt sending to logger",
					`Corrupt Log Message: ${
						errors[0].logBody
					}|Log - Errors: ${JSON.stringify(errors[0].error)}`
				);
			}
		}
	}

	/**
	 * Log type TRACE
	 * @method
	 * @param logInstance
	 * @param message
	 * @param args
	 */
	trace(logInstance, message, ...args) {
		const logMessage = this._createLogMessage(
			LOG_LEVELS.TRACE,
			logInstance,
			message,
			args
		);
		this._addToQueue(logMessage);
	}

	/**
	 * Log type DEBUG
	 * @method
	 * @param logInstance
	 * @param message
	 * @param args
	 */
	debug(logInstance, message, ...args) {
		const logMessage = this._createLogMessage(
			LOG_LEVELS.DEBUG,
			logInstance,
			message,
			args
		);
		this._addToQueue(logMessage);
	}

	/**
	 * Log type INFO
	 * @method
	 * @param logInstance
	 * @param message
	 * @param args
	 */
	info(logInstance, message, ...args) {
		const logMessage = this._createLogMessage(
			LOG_LEVELS.INFO,
			logInstance,
			message,
			args
		);
		this._addToQueue(logMessage);
	}

	/**
	 * Log type WARN
	 * @method
	 * @param logInstance
	 * @param message
	 * @param args
	 */
	warn(logInstance, message, ...args) {
		const logMessage = this._createLogMessage(
			LOG_LEVELS.WARN,
			logInstance,
			message,
			args
		);
		this._addToQueue(logMessage);
	}

	/**
	 * Log type ERROR
	 * @method
	 * @param logInstance
	 * @param message
	 * @param args
	 */
	error(logInstance, message, ...args) {
		const logMessage = this._createLogMessage(
			LOG_LEVELS.ERROR,
			logInstance,
			message,
			args
		);
		this._addToQueue(logMessage);
	}

	/**
	 * Log type FATAL
	 * @method
	 * @param logInstance
	 * @param message
	 * @param args
	 */
	fatal(logInstance, message, ...args) {
		const logMessage = this._createLogMessage(
			LOG_LEVELS.FATAL,
			logInstance,
			message,
			args
		);
		this._addToQueue(logMessage);
	}

	/**
	 * Pause logger - useful for disabling during development
	 * @method
	 */
	pause() {
		this._paused = true;
	}

	/**
	 * Unmount logger by removing timeout - will continue sending logs until there are no more logs
	 * @method
	 */
	unmount() {
		this._initializedUnmount = true;
	}
}

export default LogManager;
