/* Load missing polyfills - Most are loaded by babel-reset-env */
import React from "react";

import { render, unmountComponentAtNode } from "react-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faQuestionCircle,
	faFileAlt,
	faChevronLeft
} from "@fortawesome/free-solid-svg-icons";

import { CONFIGS, DEFAULTS } from "CONFIGS";
import "core-js/es/promise";
import EventEmitter from "eventemitter3";
import "proxy-polyfill";
import smoothscroll from "smoothscroll-polyfill";
import "whatwg-fetch";

import __PACKAGE__ from "../package.json";

import "./scss/_icons.scss";
import {
	getDataAttribute,
	shadowDOM,
	externalStylesheet,
	injectIntoHostingApplication
} from "./utils";

library.add(faQuestionCircle, faFileAlt, faChevronLeft);

class SunwingCare {
	constructor() {
		// Expose client-side library to interact with widget
		if (window && !window.WEB_CDN_SWC) {
			window.WEB_CDN_SWC = this;
		}

		if (!(window?.WEB_CDN_SWC?.isInitializing || window?.WEB_CDN_SWC?.isInitialized)) {
			this.version = __PACKAGE__.version;
			this.build = process.env.BUILD_ID;
			this._emitter = new EventEmitter();
			this._preinitializedEvents = [];
			this._reactRoot = null;
			this.isInitializing = false;
			this.isInitialized = false;
			this.init();
		}
	}

	handlePreinitializedEvents() {
		while (this._preinitializedEvents.length) {
			const event = this._preinitializedEvents.shift();
			this._emitter.emit(event.eventName, event.payload);
		}
	}

	init() {
		// Emit when widget is fully initialized
		this._emitter.on("initialized", () => {
			this.isInitializing = false;
			this.isInitialized = true;
			this.handlePreinitializedEvents();
			this.onLoad();
		});

		if (!(this.isInitializing || this.isInitialized)) {
			this.isInitializing = true;
			import("./index.scss");

			/* Initialize shadow dom */
			const [appDOM, reactRoot, reactDOM] = shadowDOM(__PACKAGE__.name);

			/* Save reference to reactRoot in order to be used to unmount */
			this._reactRoot = reactRoot;

			/* Initialize ScrollTo smooth behaviour polyfill */
			smoothscroll.polyfill();

			/* Retrieve app settings from data attributes / configuration */
			/**
			 * Get language from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default en
			 */
			const lang = getDataAttribute(appDOM, "lang", DEFAULTS.LANGUAGE);

			/* Retrieve app settings from data attributes / configuration */
			/**
			 * Get brand from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default swg
			 */
			const brand = getDataAttribute(appDOM, "brand", DEFAULTS.BRAND);

			/**
			 * Get Contentful SWG url from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default https://apiuat.sunwingapi.com/content/opn/rcl?content_type=applicationResources&environment=master&fields.application.sys.id[match]=5mOB6W4JnVYyMUij7YSzWB&limit=1000
			 */
			const cntSwgApi = getDataAttribute(appDOM, "cntSwgApi", CNT_APIM_RCL_SWG);

			/**
			 * Get Contentful VWQ url from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default https://apiuat.sunwingapi.com/content/opn/rcl?content_type=applicationResources&environment=VWQ-dev1&fields.application.sys.id[match]=5mOB6W4JnVYyMUij7YSzWB&limit=1000
			 */
			const cntVwqApi = getDataAttribute(appDOM, "cntVwqApi", CNT_APIM_RCL_VWQ);

			/**
			 * Get FormAssembly processor API url from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default https://sunwingservices.tfaforms.net/api_v2/workflow/processor
			 */
			const formAssemblyProcessorApi = getDataAttribute(
				appDOM,
				"formAssemblyProcessorApi",
				FORMASSEMBLY_PROCESSOR_API
			);

			/**
			 * Get FormAssembly Form Id from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default
			 */
			const formAssemblyFormId = getDataAttribute(
				appDOM,
				"formAssemblyFormId",
				FORMASSEMBLY_Form_ID
			);

			/**
			 * Get FormAssembly Db Control from data attribute or use default
			 * @category App Initialization
			 * @type {string}
			 * @default
			 */
			const formAssemblyDbControl = getDataAttribute(
				appDOM,
				"formAssemblyDbControl",
				FORMASSEMBLY_DB_CONTROL
			);

			/**
			 * Get Logging API url from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default https://weblogging.sunwingtravelgroup.com/api/v1/
			 */
			const loggingApi = getDataAttribute(appDOM, "loggingApi", LOGGING_API);

			const sunwingApi = getDataAttribute(appDOM, "sunwingApi", SUNWING_API);
			/**
			 * Get configuration for enabling Logging API from data attribute or use default
			 * @category App Initialization
			 * @type {string | string}
			 * @default "true"
			 */
			const enableLogging = getDataAttribute(
				appDOM,
				"enableLogging",
				ENABLE_LOGGING
			);

			/**
			 * Get stylesheet override url from data attribute or use default
			 * @category App Initialization
			 * @type {string | undefined}
			 * @default undefined
			 */
			const stylesheet = getDataAttribute(appDOM, "stylesheet", undefined);

			/* Load external stylesheet into shadow dom / dom when supplied */
			externalStylesheet(reactDOM, stylesheet);

			injectIntoHostingApplication(
				appDOM,
				"https://fonts.googleapis.com/css2?family=Heebo:wght@900&family=PT+Sans:wght@400;700&display=swap"
			);

			injectIntoHostingApplication(
				appDOM,
				"https://fonts.cdnfonts.com/css/muller-narrow"
			);

			CONFIGS.initialize({
				cntSwgApi,
				cntVwqApi,
				loggingApi,
				sunwingApi,
				enableLogging,
				formAssemblyProcessorApi,
				formAssemblyFormId,
				formAssemblyDbControl
			})
				.then(() => import("./App.js"))
				.then(({ default: App }) => {
					this._emitter.emit("initialized");
					render(
						<App
							lang={lang}
							brand={brand}
							reactDOM={reactDOM}
							appRoot={reactRoot}
							emitter={this._emitter}
						/>,
						reactRoot
					);
				});
		}

		return this;
	}

	onLoad(callback) {
		if (callback && typeof callback === "function") {
			callback(this);
		}

		return this;
	}

	overrideDefaults(settings) {
		if (!this.isInitialized) {
			this._preinitializedEvents.push({
				eventName: "override-defaults",
				payload: settings
			});
			return;
		}

		try {
			if (this._emitter) {
				this._emitter.emit("override-defaults", settings);
			}
		} catch {
			console.error("WEB.CDN.SWC | Unable to emit event");
		}
	}

	destroy() {
		if (this.isInitialized) {
			try {
				if (unmountComponentAtNode(this._reactRoot)) {
					this.isInitialized = false;
					this._emitter.off("initialized");
					this._emitter.off("update-data-layer");
				} else {
					console.error("WEB.CDN.SWC | Unable to unmount application");
				}
			} catch (error) {
				console.error("WEB.CDN.SWC | Unable to unmount application", error);
			}
		}
	}
}

window.WEB_CDN_SWC = new SunwingCare();
