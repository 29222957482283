import React from "react";
import cx from "classnames";

const renderReactChildrenWithData = (children, data) => {
	if (!children) return null;

	if (!React.isValidElement(children) && !React.Children.count(children)) {
		const component = children(data);

		if (React.isValidElement(component)) {
			return React.cloneElement(component, {
				className: cx(data?.className, component.props.className)
			});
		}

		if (React.Children.count(component)) {
			return React.Children.map(component, el =>
				React.cloneElement(el, {
					className: cx(data?.className, el.props.className)
				})
			);
		}
	}

	if (React.isValidElement(children)) {
		return React.cloneElement(children, {
			...children.props,
			...data,
			className: cx(data?.className, children.props.className)
		});
	}

	if (React.Children.count(children)) {
		return React.Children.map(children, el =>
			React.cloneElement(el, {
				...el.props,
				...data,
				className: cx(data?.className, el.props.className)
			})
		);
	}

	return null;
};

export default renderReactChildrenWithData;
export { renderReactChildrenWithData };
